import 'core-js/features/string/replace-all';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import './index.scss';
import ReactGA from 'react-ga';
import { createStore } from 'redux';
import rootReducer from './reducers';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ReactQueryDevtools } from 'react-query/devtools';
import * as serviceWorkerRegistration from './serviceWorkerRegistration'; //PWA
// import ReactPixel from 'react-facebook-pixel';

// const PIXEL_ID = '924920305590436';
// ReactPixel.init(PIXEL_ID);
// serviceWorkerRegistration.register();
serviceWorkerRegistration.unregister();
const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID; // 발급받은 추적ID를 환경 변수로 불러온다.
ReactGA.initialize(TRACKING_ID || '');
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
if (!window.location.hostname.includes('localhost'))
  Sentry.init({
    dsn: 'https://73428eb366c64065968256a6e95b8eb2@o4504410609287168.ingest.sentry.io/4505033215705088',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
const queryClient = new QueryClient();

const store = createStore(rootReducer);
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <App />

      <ReactQueryDevtools initialIsOpen={true} />
    </Provider>
  </QueryClientProvider>,
);
